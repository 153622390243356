<template>
  <v-container>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:[
      //
    ],
    data: () => ({
      //
    }),
    created() {
      this.$store.dispatch("logout");
      this.$store.dispatch("updateRolePermission");
      this.$store.dispatch("updateTeam");
      this.$router.push({ name: "PageGuestHome" });
    },
    mounted() {
      //
    },
    methods: {
      //
    }
  }
</script>